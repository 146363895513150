var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "border": "1px solid #F4CC46",
      "bg": "#F4CC4633",
      "p": "30px",
      "pr": "100px",
      "rounded": "md",
      "pos": "relative"
    }
  }, [_c('c-box', [_c('c-text', {
    attrs: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Anda belum mengisi Progress and Tracker untuk Minggu ke-" + _vm._s(_vm.week) + " ")]), _c('c-text', [_vm._v(" Klik tombol di samping untuk mengisi progress and tracker minggu ke-" + _vm._s(_vm.week) + " ")])], 1), _c('c-link', {
    attrs: {
      "bg": "#F4CC46",
      "rounded": "full",
      "h": "50px",
      "w": "50px",
      "d": "flex",
      "justify-content": "center",
      "align-items": "center",
      "as": "router-link",
      "to": _vm.clickRoute,
      "pos": "absolute",
      "top": "50%",
      "right": "20px",
      "mt": "-25px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-arrow-right-yellow.svg'),
      "h": "24px",
      "w": "24px"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }